import TreeList, { Column, Scrolling, Selection } from 'devextreme-react/tree-list';

import Box from '@mui/material/Box';
import dxTreeList, { RowClickEvent } from 'devextreme/ui/tree_list';
import useCTIds from '../../shared/hooks/use-ct-ids';
import useFetch from '../../shared/hooks/fetch-hooks/use-fetch';
import LoadingScreen from '../loading';
import useSyncUrl from '../../shared/hooks/use-sync-url';
import { EventInfo } from 'devextreme/events';
import { SelectionChangedInfo } from 'devextreme/ui/data_grid';
import { useRef } from 'react';

const OverviewTree = () => {
    const { enhIds, updateEnhIds } = useCTIds();
    const [_data, isLoading] = useFetch<any>('/map/layer/-1?mapid=12');
    const treeRef = useRef<TreeList<any, string>>(null);

    useSyncUrl();

    const onRowClick = (e: RowClickEvent) => {
        // Only keep selection if shift is pressed
        treeRef.current?.instance.selectRows((e.event?.shiftKey ? enhIds : []).concat(e.data.id.toString()), false);
    };

    const onSelectionChanged = (e: EventInfo<dxTreeList<any, string>> & SelectionChangedInfo<any, string>) => {
        if (e.selectedRowKeys !== enhIds) updateEnhIds((_ids) => e.selectedRowKeys); //setEnhIds(e.selectedRowKeys);
    };

    if (isLoading) {
        return <LoadingScreen />;
    }

    const data = _data.map((x: any) => {
        const id = x.search?.parents?.enhId?.toString();
        return {
            name: id + '   ' + x.label,
            id,
        };
    });

    return (
        <Box
            sx={{
                // Style all selected keys like the current focus
                '& tr.dx-selection': {
                    color: 'white',
                    backgroundColor: 'var(--dx-selection-color)',
                },
                '& tr.dx-selection > td': {
                    color: 'white !important',
                    backgroundColor: 'var(--dx-selection-color) !important',
                },
                'height': '100%',
            }}
        >
            {/** Tree stolen from /screens/byg/syn/tree.tsx */}
            <TreeList
                id={`tree-bygsyn`}
                dataSource={data}
                dataStructure='plain'
                columnAutoWidth={true}
                wordWrapEnabled={true}
                showColumnHeaders={false}
                showColumnLines={false}
                showRowLines={true}
                showBorders={true}
                keyExpr={'id'}
                onSelectionChanged={onSelectionChanged}
                onRowClick={onRowClick}
                cacheEnabled={false}
                focusedRowEnabled={true}
                rowAlternationEnabled={true}
                height='100%'
                defaultSelectedRowKeys={enhIds}
                focusedRowKey={enhIds.find(() => true)}
                focusStateEnabled={false}
                ref={treeRef}
            >
                <Scrolling mode={'standard'} preloadEnabled />
                <Column dataField='name' />
                <Selection />
            </TreeList>
        </Box>
    );
};

export default OverviewTree;
