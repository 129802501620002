import { Panel, PanelGroup, PanelResizeHandle } from 'react-resizable-panels';

import { CSSProperties, useState } from 'react';
import ContextPicker from './context/context-picker';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Outlet, useParams } from 'react-router-dom';
import OverviewMap from './overview.map';
import OverviewTree from './overview.tree';
import { useCTEffect } from '../../shared/hooks/use-ct';
import Button from 'devextreme-react/button';
import useHistoryNavigate from '../../shared/hooks/extended-hooks/use-history-navigate';

const handleStyle: CSSProperties = {
    width: '10px',
    backgroundColor: 'rgba(0, 0, 0, 0.12)',
};

const OverviewLayout = () => {
    const params = useParams();
    const { navigate } = useHistoryNavigate();
    const [showBackButton, setShowBackButton] = useState(params['*'] != 'Tiles');
    useCTEffect(() => {
        setShowBackButton(params['*'] != 'Tiles');
    }, [params]);
    return (
        <PanelGroup direction='horizontal'>
            <Panel defaultSize={25}>
                <div className='h-full w-full flex flex-col'>
                    <div className='flex-none w-full'>
                        <ContextPicker />
                    </div>
                    <div className='flex-1 overflow-auto'>
                        <OverviewTree />
                    </div>
                </div>
            </Panel>
            <PanelResizeHandle style={handleStyle} className='flex justify-center items-center'>
                <MoreVertIcon color='primary' />
            </PanelResizeHandle>
            <Panel defaultSize={25}>
                <OverviewMap />
            </Panel>
            <PanelResizeHandle style={handleStyle} className='flex justify-center items-center'>
                <MoreVertIcon color='primary' />
            </PanelResizeHandle>
            <Panel>
                {showBackButton ? (
                    <>
                        <div style={{ width: '100%' }}>
                            <Button
                                icon={'arrowleft'}
                                style={{ display: 'flex', width: '36px' }}
                                onClick={() => navigate('/Overview/Context/Tiles' + window.location.search)}
                            />
                        </div>
                        <div style={{ marginTop: '-36px', height: '100%' }}>
                            <Outlet />
                        </div>
                    </>
                ) : (
                    <Outlet />
                )}
            </Panel>
        </PanelGroup>
    );
};

export default OverviewLayout;
