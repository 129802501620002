import { LoaderFunctionArgs, RouteObject, redirect } from 'react-router-dom';

import OverviewLayout from './overview.layout';
import { lazy } from 'react';

const OverviewGraphsScreen = lazy(() => import('./screens/graphs.screen'));
const OverviewDebugScreen = lazy(() => import('./screens/debug.screen'));
const OverviewTilesScreen = lazy(() => import('./screens/tiles.screen'));
const OverviewForsyningScreen = lazy(() => import('./screens/forsyning.screen'));
const OverviewForsyningAdminScreen = lazy(() => import('./screens/forsyning.admin.screen'));
const OverviewSynScreen = lazy(() => import('./screens/syn.screen'));

const handleRedirect = ({ request, params }: LoaderFunctionArgs) => {
    const url = new URL(request.url);
    const { id, overviewContextName = 'Context' } = params;
    const searchParams = new URLSearchParams(url.search);
    // If id exists, set the overview equivalent
    if (!searchParams.has('enhIds') && id) {
        searchParams.set('enhIds', id.toString());
        return redirect(`${url.pathname}${url.hash}?${searchParams.toString()}`);
    }

    // return null if url contains at least 4 segments
    // param "overview" param "graph / some other segment"
    if (/(\/.+){4}/.test(url.pathname)) return null;

    return redirect(`/${id}/Overview/${overviewContextName}/Tiles${url.hash}${url.search}`);
};

const OverviewRouteObjects: RouteObject[] = [
    {
        path: '*',
        loader: handleRedirect,
        children: [
            {
                path: ':overviewContextName/*',
                loader: handleRedirect,
                element: <OverviewLayout />,
                children: [
                    {
                        path: 'Tiles',
                        element: <OverviewTilesScreen />,
                    },
                    {
                        path: 'Graphs',
                        element: <OverviewGraphsScreen />,
                    },
                    {
                        path: 'Debug',
                        element: <OverviewDebugScreen />,
                    },
                    {
                        path: 'Forsyning',
                        element: <OverviewForsyningScreen />,
                    },
                    {
                        path: 'ForsyningAdmin',
                        element: <OverviewForsyningAdminScreen />,
                    },
                    {
                        path: 'Syn',
                        element: <OverviewSynScreen />,
                    },
                ],
            },
        ],
    },
];

export default OverviewRouteObjects;
